/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Prismatic CEO Michael Zuercher joins the Constant Variables podcast to chat about why software companies should use an ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), " provider over building integrations internally, when is the right time to explore using an iPaaS, what he's taken away from growing a startup during a pandemic, and what makes acquisitions work."), "\n", React.createElement(_components.p, null, "Listen to the full episode on ", React.createElement(_components.a, {
    href: "https://constantvariables.co/episodes/95",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Constant Variables"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
